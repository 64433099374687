import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { SURVEY, STRETCH_PLAN } from '../constants/resourceId';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ request, params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  const url = new URL(request.url);
  const mailType = url.searchParams.get('mail_type');
  const editableId = url.searchParams.get('editable_id');

  if (mailType === 'survey_request') {
    return HttpResponse.json(
      {
        includeAddressee: true,
        localizedContents: [
          {
            language: 'ja',
            isDefault: true,
            isUsed: true,
            subject: 'アンケートの回答をお願いします',
            body: '職場で関わる方の成長支援を目的に、アンケートの回答にご協力ください。\n\n【 回答時の注意点 】\n本アンケートは、対象者の更なる成長に向けた「期待」を伝えるツールです。\nそのため、以下の観点にご注意の上、ご回答ください。\n\n■メリハリをつけてご回答ください\n『良いと思うところは高く』『今後、注力して取り組んで欲しいところは低く』、メリハリをつけてご回答ください。',
          },
          {
            language: 'en',
            isDefault: true,
            isUsed: true,
            subject: 'Please answer the questionnaire regarding your co-workers',
            body: 'Please fill out the questionnaire to support the growth of those involved in your workplace.\n\nCaution.\nThis questionnaire is a tool for communicating "expectations" for the further growth of the target respondents. Therefore, please answer the questionnaire with attention to the following points.\n\n*Please answer the questionnaire clearly and concisely.\nPlease answer in a balanced manner: "Highly favorable points" and "Lowly good points that we would like you to focus on in the future."\n\n*Please answer based on facts.\nPlease answer based on actual statements and actions so the target audience can accept the results with conviction.',
          },
        ],
      },
      { status: 200 }
    );
  }

  if (mailType === 'survey_reminder') {
    const res = {
      includeAddressee: true,
      localizedContents: [
        {
          language: 'ja',
          isDefault: true,
          isUsed: true,
          subject: '【再送】アンケートの回答をお願いします',
          body: '職場で関わる方の成長支援を目的に、アンケートの回答にご協力ください。\n\n【 回答時の注意点 】\n本アンケートは、対象者の更なる成長に向けた「期待」を伝えるツールです。\nそのため、以下の観点にご注意の上、ご回答ください。\n\n■メリハリをつけてご回答ください\n『良いと思うところは高く』『今後、注力して取り組んで欲しいところは低く』、メリハリをつけてご回答ください。\n\n■事実に基づいてご回答ください\n対象者の方が、納得感を持って結果を受け止められるよう、実際の発言や行動に基づいてご回答ください。',
        },
        {
          language: 'en',
          isDefault: true,
          isUsed: false,
          subject: '【Resend】Please answer the questionnaire regarding your co-workers',
          body: 'Please fill out the questionnaire to support the growth of those involved in your workplace.\n\nCaution.\nThis questionnaire is a tool for communicating "expectations" for the further growth of the target respondents. Therefore, please answer the questionnaire with attention to the following points.\n\n*Please answer the questionnaire clearly and concisely.\nPlease answer in a balanced manner: "Highly favorable points" and "Lowly good points that we would like you to focus on in the future."\n\n*Please answer based on facts.\nPlease answer based on actual statements and actions so the target audience can accept the results with conviction.',
        },
      ],
    };

    if (editableId === SURVEY.INPROGRESS_FOR_RESEND_MAIL) {
      res.localizedContents.forEach((c) => (c.isUsed = true));
    }

    return HttpResponse.json(res, { status: 200 });
  }

  if (mailType === 'stretch_plan_request') {
    return HttpResponse.json(
      {
        includeAddressee: true,
        localizedContents: [
          {
            language: 'ja',
            isDefault: true,
            isUsed: true,
            subject: 'ストレッチプランの登録をお願いします',
            body: '対象期間を通じた「目指す姿」と、その実現に向けた「アクションプラン」を、「ストレッチプラン」として登録してください。\nアクションプランを実行しやすくするため、アクションプランを具体化・詳細化した「ステップ」も、可能な限り設定してください。',
          },
          {
            language: 'en',
            isDefault: false,
            isUsed: true,
            subject: 'Please create a Stretch Plan',
            body: 'Please create a "Stretch Plan" that describes your "What I will be" through the applicable period and an "Action Plan" to achieve the "What I will be".\nTo facilitate the implementation of the Action Plan, please also set up "Steps" that concretize and detail the Action Plan as much as possible.',
          },
        ],
      },
      { status: 200 }
    );
  }

  if (mailType === 'stretch_plan_remind') {
    const res = {
      includeAddressee: true,
      localizedContents: [
        {
          language: 'ja',
          isDefault: true,
          isUsed: false,
          subject: '【再送】ストレッチプランの登録をお願いします',
          body: '※本メールは現時点でストレッチプラン未登録の方にお送りしております。\n本メールと行き違いでご対応いただいている場合は、何卒ご了承願います。\n\n対象期間を通じた「目指す姿」と、その実現に向けた「アクションプラン」を、「ストレッチプラン」として登録してください。\nアクションプランを実行しやすくするため、アクションプランを具体化・詳細化した「ステップ」も、可能な限り設定してください。',
        },
        {
          language: 'en',
          isDefault: false,
          isUsed: true,
          subject: '【Resend】Please create a Stretch Plan',
          body: '* This email is automatically delivered to individuals who have not yet created the Stretch Plan at this time.\nPlease ignore this email if you have already created.\n\nPlease create a "Stretch Plan" that describes your "What I will be" through the applicable period and an "Action Plan" to achieve the "What I will be".\nTo facilitate the implementation of the Action Plan, please also set up "Steps" that concretize and detail the Action Plan as much as possible.',
        },
      ],
    };

    if (editableId === STRETCH_PLAN.INPROGRESS_FOR_RESEND_MAIL) {
      res.localizedContents.forEach((c) => (c.isUsed = true));
    }

    return HttpResponse.json(res, { status: 200 });
  }

  if (mailType === 'stretch_plan_progress_request') {
    return HttpResponse.json(
      {
        includeAddressee: true,
        localizedContents: [
          {
            language: 'ja',
            isDefault: false,
            isUsed: true,
            subject: 'ストレッチプランの振り返り入力をお願いします',
            body: '設定した「ストレッチプラン」について、振り返りを入力しましょう。\n本施策は「成長支援」を目的としています。進捗の良し悪しに関わらず、直近の取り組み状況を記録してください。\n\n予定通りに実行できていない場合は、アクションプランやステップを見直すことも効果的です。目指す姿の実現に向けて、内容を修正しましょう。',
          },
          {
            language: 'en',
            isDefault: false,
            isUsed: true,
            subject: 'Please enter your reflections on the Stretch Plan',
            body: 'Enter your reflections on the "Stretch Plan" you have set up.\nThe purpose of this is to support growth. Please record the status of your most recent efforts, regardless of whether progress is good or bad.\n\nIf you are not able to execute as planned, it is also effective to review the action plan and steps. Revise the content to achieve your "What I will be".',
          },
        ],
      },
      { status: 200 }
    );
  }

  if (mailType === 'stretch_plan_progress_remind') {
    return HttpResponse.json(
      {
        includeAddressee: true,
        localizedContents: [
          {
            language: 'ja',
            isDefault: false,
            isUsed: true,
            subject: '【再送】ストレッチプランの振り返り入力をお願いします',
            body: '※本メールは現時点で振り返り未入力の方にお送りしております。\n本メールと行き違いでご対応いただいている場合は、何卒ご了承願います。\n\n設定した「ストレッチプラン」について、振り返りを入力しましょう。\n本施策は「成長支援」を目的としています。進捗の良し悪しに関わらず、直近の取り組み状況を記録してください。\n\n予定通りに実行できていない場合は、アクションプランやステップを見直すことも効果的です。目指す姿の実現に向けて、内容を修正しましょう。',
          },
          {
            language: 'en',
            isDefault: false,
            isUsed: false,
            subject: '【Resend】Please enter your reflections on the Stretch Plan',
            body: '* This email is automatically delivered to individuals who have already entered the reflection form at this time.\nPlease ignore this email if you have already entered.\n\nEnter your reflections on the "Stretch Plan" you have set up.\nThe purpose of this is to support growth. Please record the status of your most recent efforts, regardless of whether progress is good or bad.\n\nIf you are not able to execute as planned, it is also effective to review the action plan and steps. Revise the content to achieve your "What I will be".',
          },
        ],
      },
      { status: 200 }
    );
  }
};

export const operationCycleMailMessage = {
  get,
};
