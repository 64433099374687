import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json({
    fileDownloadUrl: 'http://localhost:50200/v1/operation/companies/1/operation_cycles/1/surveys/1/target_users/incalculable/file_download',
  });
};

export const surveyTargetUsersIncalculableFileDownloadUrl = {
  get,
};
