export const multiLineText = `改行やHTML、長いアルファベットを含むテキスト
<p>HTML</p><script>alert("alert");</script>
長いテキスト長いテキスト長いテキスト長いテキスト長いテキスト長いテキスト長いテキスト長いテキスト長いテキスト長いテキスト長いテキスト長いテキスト長いテキスト
nagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabetto
012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789
`;

export const longText100 = `<h1>HTML</h1><script>alert("alert");</script>nagaiarufabettonagaiarufabettonagaiarufabettonagaiarufa`;

export const longMailAddress =
  'anagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabetto01234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901@example.com';

export const longUserName =
  '寿限無(じゅげむ) 寿限無(じゅげむ) 五劫(ごこう)のすりきれ 海砂利(かいじゃり)水魚(すいぎょ)の水行末(すいぎょうまつ) 雲来末(うんらいまつ) 風来末(ふうらいまつ) 食(く)う寝(ね)るところに 住(す)むところ やぶらこうじの ぶらこうじ パイポ パイポ パイポの シューリンガン シューリンガンの グーリンダイ グーリンダイの ポンポコピーのポンポコナの 長久命(ちょうきゅうめい)の長助(ちょうすけ)【解説】長い名前によって起こる笑いを主題とした古典落語の一節で、「寿限無」から「長助」までが、１つの名前です。寿限りなしで死ぬことのない「寿限無」、天人が三千年に一度下界に下るたびに衣で巌を撫で、巌を刷り切るのに要する時間が一劫からくる「五劫のすりきれ」、膨大で獲り尽くせない海の幸「海砂利水魚」、衣食住は欠かせず「食う寝る所に住む所」、生命力強靭な藪柑子「やぶらこうじのぶらこうじ」、昔、唐土にあった「パイポ」という国の「シューリンガン」王と「グイーリンダイ」后のあいだに生まれ超長生きした双生児姉妹の名「ポンポコピー」と「ポンポコナ」。長久と長命を合わせて「長久命」、長く助ける「長助」から成る名前';

export const longEnglishUserName =
  'mynameisnagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabetto012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012maynameisnagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabettonagaiarufabetto0123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890127890123';
