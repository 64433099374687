export const errorCodes = {
  offlineError: 'offlineError',
  generalCommunicationError: 'generalCommunicationError',
  clientError: 'clientError',
  notFoundError: 'notFoundError',
  systemError: 'systemError',
};

export const SURVEY_STATUS = {
  inPreparation: '設定未完了',
  ready: '設定完了',
  inProgress: '回答中',
  conduct: '集計前',
  calculating: '集計中',
  calculated: '集計完了',
  published: '終了',
};

export const TRAINING_STATUS = {
  inPreparation: '設定未完了',
  ready: '設定完了',
  inProgress: '実施中',
  finished: '終了',
};

export const STRETCH_PLAN_STATUS = {
  inPreparation: '設定未完了',
  ready: '設定完了',
  inProgress: '実施中',
  finished: '終了',
};

export const SHARING_STATUS = {
  beforeSharing: '未共有',
  shared: '共有済',
};

export const MAIL_STATUS = {
  ready: '送信予定',
  processing: '送信中',
  processed: '送信完了',
  failed: '送信失敗',
  skip: 'スキップ',
};
