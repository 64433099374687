import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { SURVEY } from '~operation/mocks/constants/resourceId';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.surveyId === SURVEY.SETTING_INCOMPLETE) {
    return HttpResponse.json({
      total: 0,
      fullyCalculable: 0,
      partialCalculable: 0,
    });
  }

  if (params.surveyId === SURVEY.PASSED_START_TIME) {
    return HttpResponse.json({
      total: 0,
      fullyCalculable: 0,
      partialCalculable: 0,
    });
  }

  if (params.surveyId === SURVEY.SETTING_COMPLETE) {
    return HttpResponse.json({
      total: 10,
      fullyCalculable: 0,
      partialCalculable: 0,
    });
  }

  if (params.surveyId === SURVEY.IN_PROGRESS) {
    return HttpResponse.json({
      total: 9999,
      fullyCalculable: 2434,
      partialCalculable: 1000,
    });
  }

  if (params.surveyId === SURVEY.CAN_NOT_CALCULATE) {
    return HttpResponse.json({
      total: 10,
      fullyCalculable: 0,
      partialCalculable: 0,
    });
  }

  if (params.surveyId === SURVEY.CAN_PARTIALLY_CALCULATE) {
    return HttpResponse.json({
      total: 9999,
      fullyCalculable: 0,
      partialCalculable: 2,
    });
  }

  if (params.surveyId === SURVEY.ALL_TARGET_USERS_CALCULABLE) {
    return HttpResponse.json({
      total: 9999,
      fullyCalculable: 9999,
      partialCalculable: 0,
    });
  }

  if (params.surveyId === SURVEY.COMPLETE) {
    return HttpResponse.json({
      total: 2,
      fullyCalculable: 1,
      partialCalculable: 1,
    });
  }

  return HttpResponse.json({
    total: 2,
    fullyCalculable: 1,
    partialCalculable: 0,
  });
};

export const surveyTargetUsersStatus = {
  get,
};
