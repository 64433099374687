import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router/index';

import { getGuidFromCookie, initializeAuth0 } from './modules/auth';
import { initGa4Module } from './modules/ga4';
import * as Sentry from '@sentry/vue';
import { ignoreErrors } from './constants/ignoreErrors';
import { mockServer } from '~operation/mocks/worker';
import { VITE_APP_SENTRY_ENABLED, VITE_APP_SENTRY_DSN, VITE_APP_BUILD_DATE } from '~operation/env';
import { IS_MOCK_ENABLED } from '~operation/utility/mockFlg';

import './index.scss';
import '@lmi-mcs/ui-components/vue/styles';

const app = createApp(App);

if (VITE_APP_SENTRY_ENABLED) {
  // @see https://docs.sentry.io/platforms/javascript/guides/vue/performance/
  Sentry.init({
    app,
    dsn: VITE_APP_SENTRY_DSN,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true })],
    tracesSampleRate: 0.0,
    release: VITE_APP_BUILD_DATE,
    ignoreErrors,
  });
}

if (IS_MOCK_ENABLED) {
  await mockServer();
}

initializeAuth0().then(async () => {
  app.use(router);
  app.mount('#app');
  await initGa4Module(router);

  const guid = getGuidFromCookie();
  if (guid && !!window.gtag) {
    window.gtag('set', { user_id: guid });
  }
});
